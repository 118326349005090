import React from "react";
import { Container } from "react-bootstrap";
import { Whatsapp } from "react-bootstrap-icons";

const WhatsAppIcon = () => {
  const handleClick = () => {
    window.open("https://wa.me/8660103191", "_self");
  };

  return (
    <Container fluid>
      <div
        className="d-flex justify-content-end align-items-end position-fixed"
        style={{
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
        }}
      >
        <div
          onClick={handleClick}
          style={{
            backgroundColor: "#25d366",
            color: "white",
            borderRadius: "50%",
            padding: "15px",
            fontSize: "24px",
            cursor: "pointer",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Whatsapp />
        </div>
      </div>
    </Container>
  );
};

export default WhatsAppIcon;
