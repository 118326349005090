import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

const contents = [
  { title: "EDP TRAINING" },
  { title: "MSME BUSINESS SETUP GUIDE" },
  { title: "IDEATION TO START-UP" },
  { title: "CORPORATE VISIT" },
];
const videos = [
  { title: "BCA Sample Video" },
  { title: "BCA - LAB Sample Video" },
  { title: "B.COM - LAB Sample Video" },
];

const Entrepreneurship = () => {
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("-4rem");
    } else if (isSm) {
      setMarginTop("-5rem");
    } else if (isMd) {
      setMarginTop("-5rem");
    } else if (isLg) {
      setMarginTop("-5rem");
    } else if (isXl) {
      setMarginTop("-2rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      <Header />
      <div
        className="banner-section"
        style={{ background: "none", marginBottom: "30px" }}
      >
        <Container style={{ marginTop: marginTop }}>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Entrepreneurship Development :
          </div>
          <div className="d-flex justify-content-center text-center my-3">
            Do you have an idea to start your own company? we are there to help
            you....
          </div>
          <div
            className="my-3 text-justify"
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            Integrated training on Entrepreneurship Development Program (EDP)
            skills for undergraduate (UG) and postgraduate (PG) students is
            designed to cultivate entrepreneurial thinking and equip students
            with the skills needed to start and manage their own businesses.
            Here's a brief overview of the key benefits and components:
          </div>
          <h6>1. Cultivation of Entrepreneurial Mindset : </h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Creativity and Innovation :{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The training encourages students to think creatively and develop
                innovative solutions to real-world problems, which is essential
                for entrepreneurship.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Risk-Taking and Decision-Making :{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students learn to assess risks, make informed decisions, and
                build resilience in the face of challenges, which are critical
                traits for successful entrepreneurs.
              </span>
            </li>
          </ul>
          <h6>2. Business Planning and Strategy : </h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Business Model Development :{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated EDP training teaches students how to develop viable
                business models, including market analysis, value proposition,
                and revenue streams.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Strategic Planning :{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students gain insights into creating effective business
                strategies, setting goals, and planning for sustainable growth.
              </span>
            </li>
          </ul>

          <h6>3. Financial Management and Funding : </h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Financial Literacy :{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students learn the fundamentals of financial management,
                including budgeting, financial forecasting, and understanding
                key financial statements. analysis, value proposition, and
                revenue streams.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Access to Funding :{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The training often covers different sources of funding, such as
                venture capital, angel investors, and government grants, and how
                to pitch to potential investors.
              </span>
            </li>
          </ul>
          <h6>4. Marketing and Sales Skills:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Market Research:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students are trained in conducting market research to understand
                customer needs, market trends, and competition, which are vital
                for business success.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Sales and Customer Acquisition:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The program also focuses on developing skills in sales, customer
                relationship management, and digital marketing strategies to
                effectively reach and retain customers.
              </span>
            </li>
          </ul>

          <h6>5. Legal and Regulatory Knowledge:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Business Registration and Compliance:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                EDP training provides knowledge about the legal aspects of
                starting and running a business, including business
                registration, taxation, and compliance with local laws.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Intellectual Property (IP) Rights:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students learn about protecting their business ideas and
                innovations through intellectual property rights like patents,
                trademarks, and copyrights.
              </span>
            </li>
          </ul>

          <h6>6. Networking and Mentorship:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Industry Connections:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The program often includes opportunities to network with
                successful entrepreneurs, industry experts, and potential
                investors, which can be invaluable for business development.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Mentorship:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Access to mentors who can provide guidance, advice, and support
                is a key component, helping students navigate the challenges of
                entrepreneurship.
              </span>
            </li>
          </ul>

          <h6>7. Practical Experience and Implementation:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Live Projects and Internships:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students may get the chance to work on live entrepreneurial
                projects or internships, giving them hands-on experience in
                starting and running a business.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Startup Incubation Support:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Some programs offer incubation support, including workspace,
                resources, and guidance to help students launch their startups.
              </span>
            </li>
          </ul>

          <h6>8. Personal Growth and Leadership Development:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Leadership Skills:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The training emphasizes developing leadership qualities, such as
                team management, conflict resolution, and effective
                communication.
              </span>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Self-Motivation and Discipline:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                EDP training fosters self-motivation, discipline, and a strong
                work ethic, which are essential for entrepreneurs who need to
                drive their ventures forward.
              </span>
            </li>
          </ul>
          <h6 style={{ fontWeight: "bold", textAlign: "justify" }}>
            Conclusion:
            <div
              style={{
                fontWeight: "normal",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Integrated training on EDP skills equips UG and PG students with
              the knowledge, skills, and mindset necessary to start and
              successfully manage their own businesses. This training not only
              prepares students for entrepreneurial ventures but also enhances
              their overall employability and leadership capabilities in any
              career path they choose to pursue.
            </div>
          </h6>

          {/* <Col xs={12} md={6} sm={12} lg={5}>
              <h6>EDP TRAINING</h6>
              <h6>MSME BUSINESS SETUP GUIDE </h6>
              <h6>IDEATION TO START-UP</h6>
              <h6>CORPORATE VISIT</h6>
            </Col> */}

          <Row>
            {contents.map((content, index) => (
              <Col
                key={index}
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="mb-4 mt-4"
              >
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {content.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            View Sample Content:
          </div>
          <Row>
            {videos.map((video, index) => (
              <Col lg={4} md={4} sm={4} xs={12} key={index} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {video.title}
                        <FaFilePdf
                          style={{
                            color: "#F40F02",
                            height: "18px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="my-4">
            <Col>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Unit-wise Notes
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF- Unit-wise Q & A
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Project Work Sample
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Entrepreneurship;
