import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

const subTitle = "About   College 2 Company ";
const title =
  "Welcome to College 2 Company Enhance your skills with our Company";
const desc =
  "Welcome to “College 2 Company,” your one-stop eLearning platform designed to empower college students in achieving academic excellence and career success. At Brain Labs Education, we understand the challenges students face when transitioning from college to their dream careers, whether in the private sector or government jobs. That’s why we’ve created “College 2 Company”—a comprehensive portal that caters to all your educational and career needs.";
const desc1 =
  "Our platform offers a unique blend of resources to ensure you not only excel in your college education but also stand out in the competitive job market. Here's what we provide:";

const year = "30+";
const experience = "Years Of Experiences";

const AboutPage = () => {
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");
  const [marginLeft, setMarginLeft] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("3rem"); // MarginTop for xs devices
    } else if (isSm) {
      setMarginTop("5rem"); // MarginTop for sm devices
    } else if (isMd) {
      setMarginTop("5rem"); // MarginTop for md devices
    } else if (isLg) {
      setMarginTop("6rem"); // MarginTop for lg devices
      setMarginLeft("1rem");
    } else if (isXl) {
      setMarginTop("8rem"); // MarginTop for xl devices
      setMarginLeft("6rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <Fragment>
      <Header />
      {/* <PageHeader title={"About Our College 2 Company"} curPage={"About"} /> */}
      <div className="about-section style-3 padding-tb section-bg">
        <div className="container" style={{ marginTop: marginTop }}>
          <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
            <div className="col">
              <div className="about-left">
                <div className="about-thumb">
                  <img src="assets/images/about/01.jpg" alt="about" />
                </div>
                <div className="abs-thumb">
                  <img src="assets/images/about/02.jpg" alt="about" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="about-right">
                <div className="section-header">
                  <span className="subtitle" style={{ color: "#0a2351" }}>
                    {subTitle}
                  </span>
                  <h2 className="title">{title}</h2>
                  <p>{desc}</p>
                  <div className="my-3">{desc1}</div>

                  <h6 style={{ textTransform: "underline" }}>
                    Academic Excellence :
                    <span
                      className="text"
                      style={{ fontWeight: "normal", fontSize: "1rem" }}
                    >
                      Access syllabus-based content meticulously crafted to help
                      you master your college courses and achieve top grades.
                      Our resources are designed to align with the curriculum,
                      ensuring you are well-prepared for your exams.
                    </span>
                  </h6>
                  <h6 style={{ textTransform: "underline" }}>
                    Up-skilling for Job Market Readiness :
                    <span
                      className="text"
                      style={{ fontWeight: "normal", fontSize: "1rem" }}
                    >
                      In today's fast-paced world, staying ahead of the curve is
                      essential. We offer a range of upskilling courses tailored
                      to meet the evolving demands of the job market. Whether
                      you're looking to enhance your technical skills or develop
                      soft skills, our platform has you covered.
                    </span>
                  </h6>
                  <h6 style={{ textTransform: "underline" }}>
                    Competitive Exam Preparation :
                    <span
                      className="text"
                      style={{ fontWeight: "normal", fontSize: "1rem" }}
                    >
                      Dreaming of a government job? College 2 Company provides
                      specialized content and practice materials for various
                      competitive exams conducted by the Government of India and
                      the Karnataka State Government. Our expertguided
                      preparation modules will give you the edge you need to
                      secure a position in the public sector.
                    </span>
                  </h6>
                  <h6 style={{ textTransform: "underline" }}>
                    Employability Skills :
                    <span
                      className="text"
                      style={{ fontWeight: "normal", fontSize: "1rem" }}
                    >
                      Landing your dream job requires more than just technical
                      knowledge. We equip you with essential employability
                      skills, from resume building and interview preparation to
                      communication and problem-solving abilities, ensuring you
                      present your best self to potential employers.
                    </span>
                  </h6>
                  <div className="my-4">
                    At College 2 Company, we are committed to supporting your
                    journey from the classroom to the corporate world or the
                    public sector. Our mission is to make sure you have
                    everything you need to succeed and thrive in your chosen
                    career path. Join us today and take the first step toward a
                    brighter future!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AboutPage;
