import { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const title = "Register Now";
const socialTitle = "Register With Social Media";
const btnText = "Get Started Now";

let socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const SignupPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Register Now"} curPage={"Sign Up"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form">
              <div className="form-group">
                <select name="university" className="form-control">
                  <option>Select University</option>
                  <option value="uni1">University 1</option>
                  <option value="uni2">University 2</option>
                  <option value="uni3">University 3</option>
                </select>
              </div>
              <div className="form-group">
                <select name="college" className="form-control">
                  <option>Select College</option>
                  <option value="college1">College 1</option>
                  <option value="college2">College 2</option>
                  <option value="college3">College 3</option>
                </select>
              </div>
              <div className="form-group">
                <select name="course" className="form-control">
                  <option>Select Course</option>
                  <option value="course1">Course 1</option>
                  <option value="course2">Course 2</option>
                  <option value="course3">Course 3</option>
                </select>
              </div>
              <div className="form-group">
                <select name="semester" className="form-control">
                  <option>Select Semester</option>
                  <option value="semester1">Semester 1</option>
                  <option value="semester2">Semester 2</option>
                  <option value="semester3">Semester 3</option>
                </select>
              </div>
              <div className="form-group">
                <input type="text" name="name" placeholder="Full Name" />
              </div>

              <div className="form-group">
                <input type="email" name="email" placeholder="Email ID" />
                <button>Verify Email</button>
              </div>
              <div className="form-group">
                <input
                  type="number"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                />
              </div>
              <div className="form-group">
                <input type="text" name="address" placeholder="Address" />
              </div>
              <div className="form-group">
                <input type="text" name="studentId" placeholder="Student ID" />
              </div>
              <div className="form-group">
                <input type="text" name="password" placeholder="Password" />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="password"
                  placeholder="Confirm Password"
                />
              </div>
              <div className="form-group">
                <button className="lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Are you a member? <Link to="/login">Login</Link>
              </span>
              <span className="or">
                <span>or</span>
              </span>
              <h5 className="subtitle">{socialTitle}</h5>
              <ul className="lab-ul social-icons justify-content-center">
                {socialList.map((val, i) => (
                  <li key={i}>
                    <a href={val.link} className={val.className}>
                      <i className={val.iconName}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SignupPage;
