import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { BaseUrl } from "../BaseUrl";

import Swal from "sweetalert2";
import axios from "axios";

const title = "Login";
const socialTitle = "Login With Social Media";
const btnText = "Login";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateInput = (userName, password) => {
    const inputErrors = {};

    if (!userName.trim()) {
      inputErrors.userName = "Username is required";
    }

    if (!password.trim()) {
      inputErrors.password = "Password is required";
    }

    return inputErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { userName, password } = formData;
    const inputErrors = validateInput(userName, password);

    if (Object.keys(inputErrors).length === 0) {
      axios
        .post(`${BaseUrl}/login/v1/userLogin`, {
          userName: userName,
          password: password,
        })
        .then((response) => {
          if (response.data.responseCode === 400) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: response.data.errorMessage,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              toast: true,
              customClass: {
                container: "custom-swal-container",
              },
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            const container = document.querySelector(".swal2-container");
            if (container) {
              container.style.marginTop = "80px";
            }
          } else {
            sessionStorage.setItem("user", JSON.stringify(response.data));
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Login Successful",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              toast: true,
              customClass: {
                container: "custom-swal-container",
              },
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            const container = document.querySelector(".swal2-container");
            if (container) {
              container.style.marginTop = "80px";
            }
            navigate("/dashboard");
            setFormData({ userName: "", password: "" });
          }
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    } else {
      setErrors(inputErrors);
    }
  };

  return (
    <Fragment>
      <Header />
      <PageHeader title={"Login Page"} curPage={"Login"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form className="account-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="userName"
                  placeholder="User Name"
                  value={formData.userName}
                  onChange={handleChange}
                />
                {errors.userName && (
                  <span className="error">{errors.userName}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                  <div className="checkgroup">
                    <input type="checkbox" name="remember" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                  <Link to="/forgetpass">Forget Password?</Link>
                </div>
              </div>
              <div className="form-group text-center">
                <button type="submit" className="d-block lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/signup">Sign Up</Link>
              </span>
              <span className="or">
                <span>or</span>
              </span>

              <h5 className="subtitle">{socialTitle}</h5>
              <ul className="lab-ul social-icons justify-content-center">
                {socialList.map((val, i) => (
                  <li key={i}>
                    <a href={val.link} className={val.className}>
                      <i className={val.iconName}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default LoginPage;
