import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import MalnadAcademy from "../assets/images/competitive/Malnad Logo.jpeg";
import Officer23 from "../assets/images/competitive/3.png";
import { FaFilePdf } from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
const courses = [
  { title: "KAS – Prelims, Mains, Interview" },
  { title: "PSI, ESI, Wireless, KSISF, RFO" },
  { title: "FDA, PDO, RDPR" },
  { title: "SDA, VAO, DRFO, Police Constable" },
];

const CompetitiveExams = () => {
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("-4rem");
    } else if (isSm) {
      setMarginTop("-5rem");
    } else if (isMd) {
      setMarginTop("-5rem");
    } else if (isLg) {
      setMarginTop("-5rem");
    } else if (isXl) {
      setMarginTop("-2rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      <Header />
      <div
        className="banner-section"
        style={{ background: "none", marginBottom: "30px" }}
      >
        <Container style={{ marginTop: marginTop }}>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Competitive Exams :
          </div>
          <div className="d-flex justify-content-center text-center my-3">
            We provide Integrated Coaching for all the competitive exams
            conducted by Government of Karnataka.
          </div>

          <div className="text-center my-3">
            {/* For xs screens (images in separate rows) */}
            <div className="d-sm-none">
              <div className="row justify-content-center">
                <div className="col-12 mb-2">
                  <img
                    src={MalnadAcademy}
                    alt="Malnad Academy"
                    style={{ height: "100%", width: "100px" }}
                  />
                </div>
                <div className="col-12 mb-2">
                  In association with Malnad Academy, we offer a specially
                  designed program for Graduates called Officer @ 23.
                </div>
                <div className="col-12">
                  <img
                    src={Officer23}
                    alt="Officer @ 23"
                    style={{ height: "100%", width: "200px" }}
                  />
                </div>
              </div>
            </div>

            {/* For sm screens and above (images in the same row) */}
            <div className="d-none d-sm-flex justify-content-center align-items-center">
              <img
                src={MalnadAcademy}
                alt="Malnad Academy"
                style={{
                  height: "100%",
                  width: "100px",
                  marginRight: "10px",
                }}
              />
              <span>
                In association with Malnad Academy, we offer a specially
                designed program for Graduates called Officer @ 23.
              </span>
              <img
                src={Officer23}
                alt="Officer @ 23"
                style={{ height: "100%", width: "280px", marginLeft: "10px" }}
              />
            </div>
          </div>

          <h6>1. Efficient Time Management:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Simultaneous Preparation:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Combining academic studies with competitive exam training allows
                students to manage their time more efficiently. Instead of
                preparing separately after graduation, students can prepare for
                KPSC exams alongside their degree, saving time and reducing the
                pressure of post-graduation preparation.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Streamlined Learning:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Concepts learned in UG and PG courses, especially in subjects
                like General Studies, Economics, Political Science, and History,
                often overlap with KPSC syllabus topics. Integrated training
                ensures that students can apply their academic knowledge
                directly to their competitive exam preparation.
              </p>
            </li>
          </ul>

          <h6>2. Enhanced Academic and Exam Performance:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Reinforcement of Knowledge:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated training reinforces key concepts from UG and PG
                studies, which are often part of the KPSC exam syllabus. This
                reinforcement can lead to better academic performance and a
                stronger foundation in subjects that are tested in KPSC exams.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Skill Development:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Competitive exam preparation involves developing critical
                thinking, problem-solving, and analytical skills, which also
                enhance a student’s performance in their academic studies. These
                skills are essential for excelling in both exams and career
                development.
              </p>
            </li>
          </ul>

          <h6>3. Comprehensive Coverage of Syllabus:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Structured Learning:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Our integrated training programs are well-structured, covering
                syllabi of KPSC exam comprehensively. This structured approach
                ensures that no important topics are overlooked, providing
                students with a solid preparation strategy.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Regular Assessment:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Our regular tests and assessments in integrated programs help
                students gauge their understanding of competitive exam content,
                allowing them to identify areas of improvement early on.
              </p>
            </li>
          </ul>

          <h6>4. Financial and Resource Efficiency:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Cost-Effective:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Pursuing our integrated training is more cost-effective than
                enrolling in separate coaching programs after graduation. The
                combined approach reduces the need for additional resources, as
                students can often access study materials, coaching, and
                guidance as part of their university curriculum.
              </p>
            </li>
          </ul>

          <h6>5. Early Career Advantage:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Early Entry into Government Services:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                By preparing for KPSC exams during their UG or PG studies,
                students can potentially clear these exams earlier in their
                career, allowing them to enter government services at a younger
                age. This early entry provides a head start in terms of career
                progression and job stability.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Better Job Security:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Securing a government job through KPSC exams offers long-term
                job security and benefits. Early preparation and success in
                these exams can lead to a stable and rewarding career in various
                state government departments.
              </p>
            </li>
          </ul>

          <h6>6. Reduced Stress and Pressure:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Balanced Approach:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated training allows students to balance their academic
                and competitive exam preparation, reducing the stress and
                pressure that often comes with intense post-graduation exam
                preparation.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Continuous Learning:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The integration ensures continuous learning without a break,
                keeping students engaged and motivated throughout their UG and
                PG studies. This continuous engagement can lead to a more
                holistic and less stressful preparation process.
              </p>
            </li>
          </ul>

          <h6>7. Increased Confidence and Motivation:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Building Confidence:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Continuous preparation and regular assessment build confidence
                in students, as they become more familiar with the exam pattern,
                question types, and time management strategies. This confidence
                is crucial for performing well in competitive exams like those
                conducted by KPSC.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Peer Motivation:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Being part of an integrated program often means studying with
                peers who share similar goals. This peer group can provide
                motivation, support, and a healthy competitive environment that
                enhances overall preparation.
              </p>
            </li>
          </ul>

          <h6>8. Long-Term Career Benefits:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Holistic Development:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated training programs emphasize both academic excellence
                and competitive exam readiness, leading to a more well-rounded
                individual. This holistic development is beneficial not just for
                clearing exams, but also for succeeding in a long-term career in
                public service.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Networking Opportunities:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated programs often provide opportunities to interact with
                professionals in the field, alumni who have cleared KPSC exams,
                and other aspirants. These interactions can offer valuable
                insights, guidance, and networking opportunities that may
                benefit students in their future careers.
              </p>
            </li>
          </ul>

          <h6>9. Alignment with Career Goals:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Focused Preparation:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated training aligns students’ academic pursuits with
                their career goals in public service. This focused approach
                ensures that students are consistently working towards their
                desired career path, with the academic and competitive exam
                preparation reinforcing each other.
              </p>
            </li>
          </ul>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            KPSC Courses Offered:
          </div>
          <Row>
            {courses.map((course, index) => (
              <Col key={index} xs={12} sm={6} md={6} lg={3} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {course.title}
                        <FaFilePdf
                          style={{
                            color: "#F40F02",
                            height: "18px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default CompetitiveExams;
