import { Link } from "react-router-dom";

const siteTitle = "Site Map";
const useTitle = "Useful Links";
const socialTitle = "Social Contact";
const supportTitle = "Get in Touch";

const siteList = [
  {
    text: "Documentation",
    link: "#",
  },
  {
    text: "Feedback",
    link: "#",
  },
  {
    text: "Plugins",
    link: "#",
  },
  {
    text: "Support Forums",
    link: "#",
  },
  {
    text: "Themes",
    link: "#",
  },
];

const useList = [
  {
    text: "About Us",
    link: "about",
  },
  {
    text: "Help Center",
    link: "#",
  },
  {
    text: "Terms & Conditions",
    link: "#",
  },
  {
    text: "Privacy Policy",
    link: "#",
  },
  {
    text: "Refund Policy",
    link: "#",
  },
];

const socialList = [
  {
    text: "Facebook",
    link: "#",
  },
  {
    text: "Instagram",
    link: "#",
  },
  {
    text: "YouTube",
    link: "#",
  },
  {
    text: "Twitter",
    link: "#",
  },
  {
    text: "Linkedin",
    link: "#",
  },
];

const supportList = [
  {
    imgUrl: "assets/images/icon/01.png",
    imgAlt: "address",
    text: "98/C,12th Cross Road,Remco bhel Layout,Ideal Homes Twp, Rajarajeshwari Nagar,Bengaluru,Karnataka 560098",
    link: "#",
  },
  {
    imgUrl: "assets/images/icon/02.png",
    imgAlt: "contact",
    text: "+91 8660103191",
    link: "#",
  },
  {
    imgUrl: "assets/images/icon/03.png",
    imgAlt: "email",
    text: "info@brainlabseducation.com",
    link: "#",
  },
  // {
  //   imgUrl: "assets/images/icon/04.png",
  //   imgAlt: "website",
  //   text: "https://www.college2company.org",
  //   link: "#",
  // },
];

const Footer = () => {
  return (
    <div className="news-footer-wrap">
      <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div>

      <footer>
        <div className="footer-top padding-tb pt-0">
          <div className="container">
            <div className="row g-4 row-cols-xl-3 row-cols-md-3 row-cols-1 justify-content-center">
              {/* <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{siteTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div> 
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{useTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {useList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {socialList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i} style={{ marginBottom: "10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={val.imgUrl}
                                  alt={val.imgAlt}
                                  style={{
                                    marginRight: "20px",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                                <a href={val.link} s>
                                  {val.text}
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom style-2">
          <div className="container">
            <div className="section-wrapper">
              <p>
                &copy; 2024 <Link to="/">College2Company</Link> Designed by
                <a href="https://walkinsoftwares.com/" target="_blank">
                  Wakin Softwares Bharat Pvt.ltd
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
