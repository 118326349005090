import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { useEffect } from "react";

const interviews = [
  { title: "COMMUNICATION" },
  { title: "TEAM WORK" },
  { title: "LEADERSHIP" },
  { title: "TIME MANAGEMENT" },
  { title: "WORK ETHICS" },
  { title: "GROUP DISCUSSIONS" },
  { title: "MOCK INTERVIEWS" },
];
const teams = [
  { title: "Industry Experts" },
  { title: "HR Professionals from MNCs" },
  { title: "Certified Trainers" },
];

const videos = [
  { title: "BCA Sample Video" },
  { title: "BCA - LAB Sample Video" },
  { title: "B.COM - LAB Sample Video" },
];

const Employability = () => {
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("-4rem");
    } else if (isSm) {
      setMarginTop("-5rem");
    } else if (isMd) {
      setMarginTop("-5rem");
    } else if (isLg) {
      setMarginTop("-5rem");
    } else if (isXl) {
      setMarginTop("-2rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      <Header />
      <div
        className="banner-section"
        style={{ background: "none", marginBottom: "30px" }}
      >
        <Container style={{ marginTop: marginTop }}>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Employability Skills :
          </div>
          <div className="d-flex justify-content-center text-center my-3">
            We provide Integrated training to develop your soft skills,
            interview facing skills and how to build your resume as per today’s
            market requirements.
          </div>
          <div
            className=" my-3"
            style={{
              fontWeight: "normal",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            Integrated training on employability skills for undergraduate (UG)
            and postgraduate (PG) students focuses on equipping them with the
            essential skills needed to succeed in the modern workplace. Here's a
            brief overview of the benefits and components of such training:
          </div>

          <h6>1. Enhanced Job Readiness:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Practical Skills:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated training programs emphasize practical skills such as
                communication, teamwork, problem-solving, and time management.
                These are crucial for students to perform effectively in any
                professional environment.
              </p>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Industry-Relevant Knowledge:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students gain exposure to industry-specific skills, such as
                digital literacy, project management, and data analysis, making
                them more attractive to employers.
              </p>
            </li>
          </ul>

          <h6>2. Soft Skills Development:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Communication:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Training focuses on improving both verbal and written
                communication, enabling students to articulate their ideas
                clearly and confidently in professional settings.
              </p>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Leadership and Teamwork:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students learn how to lead teams, collaborate with others, and
                work effectively in diverse groups, which are critical skills in
                any career.
              </p>
            </li>
          </ul>

          <h6>3. Improved Confidence and Adaptability:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Real-World Exposure:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated training often includes internships, workshops, and
                real-world projects, helping students build confidence and
                adaptability to different work environments.
              </p>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Interview Preparation:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Mock interviews, resume building, and networking skills are
                often part of the training, ensuring students are well-prepared
                for job interviews and recruitment processes.
              </p>
            </li>
          </ul>

          <h6>4. Competitive Edge in the Job Market:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Personal Branding:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students learn how to create a strong personal brand, both
                online and offline, which helps them stand out in a competitive
                job market.
              </p>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Career Planning:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Guidance on career planning and job search strategies helps
                students make informed decisions about their future career
                paths.
              </p>
            </li>
          </ul>

          <h6>5. Lifelong Learning and Growth:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Continuous Improvement:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The training encourages a mindset of lifelong learning, where
                students are motivated to continuously update their skills to
                stay relevant in their careers.
              </p>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Networking Opportunities:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Integrated programs often provide opportunities to connect with
                industry professionals, alumni, and peers, fostering a network
                that can be valuable for career growth.
              </p>
            </li>
          </ul>

          <h6>6. Customization to Academic Fields:</h6>
          <ul>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Field-Specific Skills:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                The training is often tailored to align with the students'
                academic fields, ensuring that the skills they learn are
                directly applicable to their chosen careers.
              </p>
            </li>
            <li style={{ fontWeight: 600, textAlign: "justify" }}>
              Cross-Disciplinary Learning:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Students may also be exposed to skills outside their core
                discipline, providing them with a broader perspective and
                versatility in the job market.
              </p>
            </li>
          </ul>

          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              marginTop: "1rem",
            }}
          >
            Integrated training on employability skills prepares UG and PG
            students not just for their first job, but for a successful career
            by equipping them with the necessary skills, confidence, and mindset
            to thrive in today’s dynamic job market.
          </p>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            INTERVIEW PREPARATIONS:
          </div>
          <Row>
            {interviews.map((interview, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {interview.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Our Team:
          </div>
          <Row>
            {teams.map((team, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {team.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            View Sample Content:
          </div>
          <Row>
            {videos.map((video, index) => (
              <Col lg={4} md={4} sm={4} xs={12} key={index} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {video.title}
                        <FaFilePdf
                          style={{
                            color: "#F40F02",
                            height: "18px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="my-4">
            <Col>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Unit-wise Notes
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF- Unit-wise Q & A
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Project Work Sample
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Employability;
