import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Bookmark } from "react-bootstrap-icons"; // Import the bookmark icon from react-bootstrap-icons
import Header from "../layout/header";

const Dashboard = () => {
  return (
    <>
      <Header />
      <section className="banner-section">
        <Container>
          <div className="section-wrapper">
            <Row className="align-items-center justify-content-between">
              {/* Left side - Logo and University Text */}
              <Col
                xs="auto"
                className="d-flex align-items-center"
                style={{ marginTop: "-20px" }}
              >
                <img
                  src="/path/to/logo.png"
                  alt="Logo"
                  style={{ maxHeight: "50px" }}
                />
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  Government First Grade College, Raichur
                </span>
              </Col>
              {/* Middle - Address */}
              <Col className="text-center">
                <address style={{ fontStyle: "normal", fontSize: "16px" }}>
                  Welcome Mr. Gurudath, Government First Grade College, Raichur
                </address>
              </Col>
              {/* Right side - View Bookmarks */}
              <Col
                xs="auto"
                className="d-flex align-items-center"
                style={{ marginTop: "-15px" }}
              >
                <Bookmark style={{ marginRight: "5px", color: "green" }} />
                <span>View Bookmarks</span>
              </Col>
            </Row>

            {/* Labels Row */}
            <Row className="align-items-center">
              <Col xs={12} md={4}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    display: "block",
                    marginTop: "30px",
                  }}
                >
                  Subjects List - B.COM - SEMESTER 1
                </span>
              </Col>
              <Col xs={12} md={4}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    display: "block",
                    marginTop: "30px",
                  }}
                >
                  Up-Skill
                </span>
              </Col>
              <Col xs={12} md={4}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    display: "block",
                    marginTop: "30px",
                  }}
                >
                  Competitive Exams
                </span>
              </Col>
            </Row>

            {/* Checkboxes Row */}
            <Row className="mt-3">
              <Col xs={12} md={4}>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Semester 1"
                    id="sem1"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Semester 2"
                    id="sem2"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Semester 3"
                    id="sem3"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Semester 4"
                    id="sem4"
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Up-Skill 1"
                    id="upskill1"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Up-Skill 2"
                    id="upskill2"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Up-Skill 3"
                    id="upskill3"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Up-Skill 4"
                    id="upskill4"
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div>
                  <Form.Check
                    type="checkbox"
                    label="Exam 1"
                    id="exam1"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Exam 2"
                    id="exam2"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Exam 3"
                    id="exam3"
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Exam 4"
                    id="exam4"
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
