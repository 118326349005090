import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaFilePdf } from "react-icons/fa";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { useEffect } from "react";

const universities = [
  { imgUrl: "assets/images/offers/icon/13.png", title: "BANGALORE" },
  { imgUrl: "assets/images/offers/icon/06.png", title: "BANGALORE CITY" },
  { imgUrl: "assets/images/offers/icon/12.png", title: "BANGALORE NORTH" },
  { imgUrl: "assets/images/offers/icon/11.png", title: "BIDAR" },
  { imgUrl: "assets/images/offers/icon/01.png", title: "DAVANAGERE" },
  { imgUrl: "assets/images/offers/icon/14.png", title: "DHARWAD" },
  { imgUrl: "assets/images/offers/icon/02.png", title: "GULBARGA" },
  { imgUrl: "assets/images/offers/icon/04.png", title: "MYSORE" },
  { imgUrl: "assets/images/offers/icon/05.png", title: "MANGALORE" },
  { imgUrl: "assets/images/offers/icon/03.png", title: "KUVEMPU" },
  { imgUrl: "assets/images/offers/icon/08.png", title: "RAICHUR" },
  { imgUrl: "assets/images/offers/icon/09.png", title: "RANI CHENNAMMA" },
  { imgUrl: "assets/images/offers/icon/10.png", title: "TUMKUR" },
  { imgUrl: "assets/images/offers/icon/07.png", title: "KSAWU" },
  { imgUrl: "assets/images/offers/icon/15.jpeg", title: "VTU" },
];
const courses = [
  { title: "BCA" },
  { title: "B.COM" },
  { title: "BBA" },
  { title: "MBA" },
  { title: "MCA" },
];
const contents = [
  { title: "Lecture Videos" },
  { title: "Unit-wise Notes" },
  { title: "Unit-wise Q & A" },
  { title: "Lab Work with Source Code" },
  { title: "Project Work Samples and Support" },
];
const videos = [
  { title: "BCA Sample Video" },
  { title: "BCA - LAB Sample Video" },
  { title: "B.COM - LAB Sample Video" },
];

const Academy = () => {
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("-4rem");
    } else if (isSm) {
      setMarginTop("-5rem");
    } else if (isMd) {
      setMarginTop("-5rem");
    } else if (isLg) {
      setMarginTop("-5rem");
    } else if (isXl) {
      setMarginTop("-2rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      <Header />
      <div
        className="banner-section"
        style={{ background: "none", marginBottom: "30px" }}
      >
        <Container style={{ marginTop: marginTop }}>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Academic Content :
          </div>
          <div className="d-flex justify-content-center text-center my-3">
            We provide all the required study materials to excel in your
            academics as per the syllabus prescribed by your respective
            universities.
          </div>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Learn as Per Syllabus:
          </div>
          <div
            style={{
              fontWeight: "normal",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <div className="my-3">
              One-stop portal & Mobile App for “Syllabus” focussed content for
              Undergraduate (UG) and Postgraduate (PG) courses of Universities
              in Karnataka.
            </div>
            <div className="my-3">
              Our “Syllabus Focused Studies” approach students to concentrate
              their efforts on the topics, concepts, and skills that are covered
              in the syllabus for a particular course. This approach will be
              highly effective for students who want to ensure that they have a
              thorough understanding of the material that will be covered on
              exams and assignments.
            </div>
            <div className="my-3">
              We provide lecture videos, unit-wise notes, unit-wise Q & A,
              Solved Lab Assignments with source code, etc., which would help
              the students to understand the scope and sequence of the course,
              as well as the learning objectives and assessment methods. Be sure
              to pay close attention to any reading assignments, and Unit-wise
              AI based Self-Assessment-Test (SAT).
            </div>
            <div className="my-3">
              As the students work through the course materials, they can “BOOK
              MARK” the important topics and organize the content in a way that
              corresponds to the syllabus. They can make a list of the key
              concepts and skills that are covered in the syllabus. This will
              help them prioritize studying and ensure that they are focusing on
              the most important material.
            </div>
            <div className="my-3">
              Overall, syllabus-focused studies can be a highly effective
              approach to learning. By focusing your efforts on the material
              that will be covered on exams and assignments, you can ensure that
              you are well-prepared and confident in your understanding of the
              course material.
            </div>
          </div>

          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Salient Features:
          </div>
          <ol className="my-3">
            <li style={{ fontWeight: 600 }}>
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                University Prescribed Syllabus Focussed Content - Syllabi of all
                major Universities in Karnataka & VTU are covered.
              </span>
            </li>
            <li style={{ fontWeight: 600 }}>
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Lecture Videos, Unit-wise Notes, and Unit-wise Q & A from highly
                qualified and experienced faculties.
              </span>
            </li>

            <li style={{ fontWeight: 600 }}>
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Lab work with step-by-step explanation, execution, and source
                code to practice in your local environment.
              </span>
            </li>

            <li style={{ fontWeight: 600 }}>
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Project work assistance with Problem statements, Project Output
                & Documentation.
              </span>
            </li>

            <li style={{ fontWeight: 600 }}>
              <span
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Case studies to understand the concept better.
              </span>
            </li>
          </ol>

          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Learn anywhere:
          </div>
          <div>
            College2Company provides - Web, Mobile, Tab – compatible content
          </div>
          <div
            style={{
              fontWeight: "normal",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <div className="my-3">
              "Learn Anywhere" refers to the concept of being able to learn and
              acquire knowledge from anywhere, at any time, and using any
              device. With the advent of technology and the internet, learning
              has become more accessible and convenient than ever before.
              Whether you're at home, in a coffee shop, or on a plane, you can
              access our educational resources online.
            </div>
            <div className="my-3">
              One of the most significant benefits of learning anywhere is the
              flexibility it provides. Traditional education often requires
              students to attend classes at specific times and locations, which
              can be challenging for individuals with busy schedules or those
              who live in remote areas. However, with online learning, you can
              access coursework, lectures, and educational resources from
              anywhere in the world, at any time of day or night, and learn at
              your own pace
            </div>
          </div>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>
            Learn with experts:
          </div>

          <div
            style={{
              fontWeight: "normal",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            <div className="my-3">
              The Content is provided by “Highly Qualified, Professors,
              Doctorates with an average teaching experience of 12+ years”.
            </div>
            <div className="my-3">
              "Learn with Experts" is a phrase that implies the idea of gaining
              knowledge, skills, or insights from individuals who have
              significant experience or expertise in a particular field or
              subject matter. Learning from experts can be beneficial in various
              ways, as it offers the opportunity to obtain a deep understanding
              of a topic or skill, learn from real-world examples and case
              studies, receive personalized feedback and guidance, and gain
              insights into industry best practices and trends.
            </div>
            <div className="my-3">
              Our programs provide a structured and comprehensive approach to
              learning, and offer the opportunity to interact with and learn
              from experienced professionals.
            </div>
            <div className="my-3">
              Our mentors can provide personalized guidance, feedback, and
              support, as well as share their own experiences and insights.
            </div>
            <div className="my-3">
              Our future goal is to provide Webinars and podcasts hosted by
              experts that can be an excellent way to gain knowledge and
              insights on specific topics or industries. These platforms allow
              for interactive engagement with the expert and other participants,
              providing a unique opportunity for collaborative learning.
            </div>
          </div>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Universities Covered:
          </div>
          <Row>
            {universities.map((university, index) => (
              <Col
                key={index}
                className="card-col card-col-lg card-col-md card-col-sm card-col-xs"
              >
                <Card className="card-style">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={university.imgUrl}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "contain",
                        marginTop: 10,
                      }}
                    />
                  </div>
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {university.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Courses Offered:
          </div>
          <Row>
            {courses.map((course, index) => (
              <Col
                key={index}
                className="card-col card-col-lg card-col-md card-col-sm card-col-xs"
              >
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {course.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Types of Content:
          </div>
          <Row>
            {contents.map((content, index) => (
              <Col
                key={index}
                className="card-col card-col-lg card-col-md card-col-sm card-col-xs"
              >
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {content.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            View Sample Content:
          </div>
          <Row>
            {videos.map((video, index) => (
              <Col lg={4} md={4} sm={4} xs={12} key={index} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {video.title}
                      <FaFilePdf
                        style={{
                          marginLeft: "20px",
                          color: "#F40F02",
                          height: "18px",
                        }}
                      />
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="my-4">
            <Col>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Unit-wise Notes
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF- Unit-wise Q & A
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Project Work Sample
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Academy;
