import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./banner.css"; // Import your custom CSS file

const title = (
  <h2 className="title">
    <span className="d-lg-block">
      Begin the Beautiful Journey from College TO Company with us
    </span>
  </h2>
);

const desc = "WE PROVIDE";
const header1 = "Academic Content";
const desc1 =
  " Syllabus Focussed content for Undergraduate & Postgraduate courses ";
const header2 = "Up-skilling";
const desc2 =
  " Job Market Focussed, highly in-demand courses for Science and Commerce students";
const header3 = "Competitive Exams";
const desc3 = " KPSC and Banking Exams";
const header4 = "Employability Skills";
const desc4 = " Soft skills, Resume Building, Interview Skills";
const header5 = "Entrepreneurship Development";
const desc5 = " Start-Up Guidance & Assistance";

const shapeList = [
  {
    name: "Academic Content",
    link: "",
    className: "ccl-shape shape-1",
  },
  {
    name: "Up-skilling",
    link: "",
    className: "ccl-shape shape-2",
  },
  {
    name: "Competitive Exams",
    link: "",
    className: "ccl-shape shape-3",
  },
  {
    name: "Employability Skills",
    link: "",
    className: "ccl-shape shape-4",
  },
  {
    name: "Entrepreneurship Development",
    link: "",
    className: "ccl-shape shape-5",
  },
];

const Banner = () => {
  // Media queries for different screen sizes
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");
  const [marginLeft, setMarginLeft] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("-4rem"); // MarginTop for xs devices
    } else if (isSm) {
      setMarginTop("-5rem"); // MarginTop for sm devices
    } else if (isMd) {
      setMarginTop("-5rem"); // MarginTop for md devices
    } else if (isLg) {
      setMarginTop("-5rem"); // MarginTop for lg devices
      setMarginLeft("1rem");
    } else if (isXl) {
      setMarginTop("-2rem"); // MarginTop for xl devices
      setMarginLeft("6rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);

  return (
    <section className="banner-section" style={{ background: "none" }}>
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-center">
            <div
              className="col-xxl-5 col-xl-6 col-lg-10 banner-text-container"
              style={{ marginTop: marginTop, marginBottom: "4rem" }}
            >
              <div className="banner-text-content">
                <div>{title}</div>

                <p className="subtitle" style={{ color: "#CE2029" }}>
                  {desc}
                </p>
                <div className="h5" style={{ fontWeight: 600 }}>
                  {header1} -
                  <span
                    className="text"
                    style={{ fontWeight: "normal", fontSize: "1rem" }}
                  >
                    {desc1}
                    <Link
                      to="/academy"
                      style={{ textTransform: "underline", color: "green" }}
                    >
                      .......know more....
                    </Link>
                  </span>
                  <p
                    className="h6"
                    style={{
                      fontWeight: "bold",
                      margin: "18px 0",
                      color: "#5072A7",
                    }}
                  >
                    AND - INTEGRATED COURSES, ALONG WITH YOUR ACADEMIC STUDIES
                  </p>
                </div>

                <div className="h5" style={{ fontWeight: 600 }}>
                  {header2} -
                  <span
                    className="text"
                    style={{ fontWeight: "normal", fontSize: "1rem" }}
                  >
                    {desc2}
                    <Link
                      to="/upskills"
                      style={{ textTransform: "underline", color: "green" }}
                    >
                      .......know more....
                    </Link>
                  </span>
                </div>

                <div className="h5" style={{ fontWeight: 600, paddingTop: 10 }}>
                  {header3} -
                  <span
                    className="text"
                    style={{ fontWeight: "normal", fontSize: "1rem" }}
                  >
                    {desc3}
                    <Link
                      to="/competitive-exams"
                      style={{ textTransform: "underline", color: "green" }}
                    >
                      .......know more....
                    </Link>
                  </span>
                </div>

                <div className="h5" style={{ fontWeight: 600, paddingTop: 10 }}>
                  {header4} -
                  <span
                    className="text"
                    style={{ fontWeight: "normal", fontSize: "1rem" }}
                  >
                    {desc4}
                    <Link
                      to="/employability-skills"
                      style={{ textTransform: "underline", color: "green" }}
                    >
                      .......know more....
                    </Link>
                  </span>
                </div>
                <div className="h5" style={{ fontWeight: 600, paddingTop: 10 }}>
                  {header5} -
                  <span
                    className="text"
                    style={{ fontWeight: "normal", fontSize: "1rem" }}
                  >
                    {desc5}
                    <Link
                      to="/entrepreneurship"
                      style={{ textTransform: "underline", color: "green" }}
                    >
                      .......know more....
                    </Link>
                  </span>
                </div>
                {/* 
                <div className="h5" style={{ fontWeight: 600, paddingTop: 10 }}>
                  dummy -
                  <span
                    className="text"
                    style={{ fontWeight: "normal", fontSize: "1rem" }}
                  >
                    {desc5}
                    <Link
                      to="/entrepreneurship"
                      style={{ textTransform: "underline", color: "green" }}
                    >
                      .......know more....
                    </Link>
                  </span>
                </div> */}
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <div
                className="banner-thumb"
                style={{
                  marginTop: "-8rem",
                  marginBottom: "6rem",
                  marginLeft: marginLeft,
                }}
              >
                <img src="assets/images/banner/01.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-shapes"></div>
      <div className="cbs-content-list d-none">
        <ul className="lab-ul">
          {shapeList.map((val, i) => (
            <li className={val.className} key={i}>
              <a href={val.link}>{val.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Banner;
