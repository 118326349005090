import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaFilePdf } from "react-icons/fa";
import "./upSkillsStyle.css";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { useEffect } from "react";

const courses = [
  { title: "Full Stack Development – JAVA, MEAN, MERN" },
  { title: "Android, iOS Mobile App Development" },
  { title: "Cloud Computing" },
  { title: "Testing" },
  { title: "Cyber Security" },
  { title: "IOT" },
];
const commerceCourses = [
  { title: "Digital Marketing" },
  { title: "HR Management & Analytics" },
  { title: "Supply Chain Management" },
  { title: "Retail Management" },
  { title: "Business Analytics" },
  { title: "Hospitality Management" },
  { title: "Entrepreneurship & Innovation" },
  { title: "Investment Banking" },
  { title: "Stock Market Analysis" },
  { title: "UI/UX" },
];
const contents = [
  { title: "Lecture Videos" },
  { title: "Practical Implementation" },
  { title: "Tools & Techniques" },
  { title: "Real world examples/test cases" },
  { title: "Project Work " },
];
const videos = [
  { title: "BCA Sample Video" },
  { title: "BCA - LAB Sample Video" },
  { title: "B.COM - LAB Sample Video" },
];

const Upskills = () => {
  const isXs = useMediaQuery({ maxWidth: 576 });
  const isSm = useMediaQuery({ minWidth: 577, maxWidth: 768 });
  const isMd = useMediaQuery({ minWidth: 769, maxWidth: 992 });
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isXl = useMediaQuery({ minWidth: 1201 });

  const [marginTop, setMarginTop] = useState("1rem");

  useEffect(() => {
    if (isXs) {
      setMarginTop("-4rem");
    } else if (isSm) {
      setMarginTop("-5rem");
    } else if (isMd) {
      setMarginTop("-5rem");
    } else if (isLg) {
      setMarginTop("-5rem");
    } else if (isXl) {
      setMarginTop("-2rem");
    }
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      <Header />
      <div
        className="banner-section"
        style={{ background: "none", marginBottom: "30px" }}
      >
        <Container style={{ marginTop: marginTop }}>
          <div style={{ fontSize: "25px", fontWeight: 600 }}>Up-Skilling :</div>
          <div className="d-flex justify-content-center text-center my-3">
            We provide course materials for Job Market Focussed,
            Highly-in-demand Courses for Science and Commerce students.
          </div>

          <h6>1. Enhanced Employability:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Commerce:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Skills in Financial analysis, Business Analytics, HR, SCM,
                Digital marketing, etc., can make graduates more competitive in
                the job market, especially in sectors like banking, finance, and
                e-commerce.
              </p>
            </li>
            <li style={{ fontWeight: 600 }}>
              Science:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Proficiency in emerging technologies like Full Stack
                Development, Mobile App Development, Data Science, AI, Cyber
                Security, Testing, etc., are highly valued. This makes science
                graduates more attractive to industries like IT, healthcare, and
                research and development.
              </p>
            </li>
          </ul>

          <h6>2. Better Career Progression:</h6>
          <ul>
            <li style={{ fontWeight: 600 }}>
              Commerce:
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Up-skilling can lead to faster career advancement, potentially
                opening doors to managerial roles or specialized positions.
                Acquiring additional qualifications in niche fields can lead to
                roles in high-demand industries and better opportunities for
                research or higher education.
              </p>
            </li>
          </ul>

          <h6>3. Adaptability to Industry Changes:</h6>
          <ul>
            <li>
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                With the rapid changes in business models due to digital
                transformation, up-skilling helps students and professionals
                stay relevant, ensuring they can adapt to new tools and
                practices. As scientific fields evolve with new discoveries and
                technologies, continuous learning and up-skilling ensure that
                professionals remain at the cutting edge of their respective
                fields.
              </p>
            </li>
          </ul>

          <h6>4. Entrepreneurship and Innovation:</h6>
          <ul>
            <li>
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Skills in business strategy, financial management, and market
                analysis are crucial for those aspiring to start their own
                businesses or work in startups. Up-skilling in innovation
                management, intellectual property rights, and technology
                commercialization can be beneficial for those interested in
                launching tech-based startups or engaging in product
                development.
              </p>
            </li>
          </ul>

          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              marginTop: "1rem",
            }}
          >
            In summary, up-skilling in both commerce and science not only
            enhances immediate job prospects but also ensures long-term career
            sustainability and growth.
          </p>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Courses Offered – Science – BCA & MCA:
          </div>
          <Row>
            {courses.map((course, index) => (
              <Col key={index} xs={6} sm={6} md={4} lg={4} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {course.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Courses Offered – B.COM, BBA & MBA:
          </div>
          <Row>
            {commerceCourses.map((course, index) => (
              <Col key={index} xs={6} sm={6} md={4} lg={3} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {course.title}
                        <FaFilePdf
                          style={{
                            color: "#F40F02",
                            height: "18px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            Types of Content:
          </div>
          <Row>
            {contents.map((content, index) => (
              <Col
                key={index}
                className="card-col card-col-lg card-col-md card-col-sm card-col-xs"
              >
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      {content.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <div
            style={{
              fontSize: "25px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            View Sample Content:
          </div>
          <Row>
            {videos.map((video, index) => (
              <Col lg={4} md={4} sm={4} xs={12} key={index} className="mb-4">
                <Card className="card-style">
                  <Card.Body>
                    <Card.Title style={{ fontSize: "15px", fontWeight: 600 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {video.title}
                        <FaFilePdf
                          style={{
                            color: "#F40F02",
                            height: "18px",
                            marginLeft: "8px",
                          }}
                        />
                      </div>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="my-4">
            <Col>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Unit-wise Notes
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF- Unit-wise Q & A
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaFilePdf style={{ marginRight: "5px", color: "#F40F02" }} />
                PDF-Project Work Sample
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Upskills;
